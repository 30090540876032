import type { GetStaticProps, NextPage } from 'next'
import Login from 'components/pages/Login'

const Page: NextPage = () => {
  return <Login />
}

export default Page

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      layout: {
        plain: true,
      },
    },
  }
}
