import React, { Component } from 'react'
import { withRouter } from 'next/router'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import { NextLink } from 'components/common/NextLink'
import LoginForm from 'components/forms/LoginForm'
import FirebaseManager from 'utils/FirebaseManager'
import { TOP } from 'constants/Route'
import logo from 'images/legacy/onepair-logo.svg?url'
import { mixins } from 'styles/mixins'

const Content = styled.div`
  ${mixins.flex()}

  flex-flow: column nowrap;
  height: 100vh;

  a {
    color: #323232;

    &:hover {
      opacity: 0.5;
    }
  }
`

const SessionErrorContainer = styled.div`
  ${mixins.flex()}

  position: fixed;
  top: 0;
  width: 100%;
  height: 40px;
  font-size: 1.25rem;
  color: #fff;
  background-color: rgb(197 0 0 / 50%);
`

const AppLogo = styled.img`
  width: 200px;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin: 20px;
`

const LoginFormContainer = styled.div`
  ${mixins.flex()}

  flex-flow: column nowrap;
  margin: 20px auto;
  border: 1px solid #d7d7d7;
  box-shadow: 0 0 4px 0 rgb(173 173 173 / 50%);
`

class Login extends Component {
  constructor(props) {
    super(props)

    const sessionError = localStorage.getItem('sessionError')
    if (sessionError) {
      this.state.sessionError = sessionError
    }

    this.login = this.login.bind(this)
  }

  state = {
    isSuccess: false,
    sessionError: '',
    signInError: null,
  }

  login(values, setSubmitting) {
    const email = values.email
    const password = values.password

    // 念の為空で入力させない
    if (isEmpty(email) || isEmpty(password)) {
      alert('必須項目が入力されていません。')
      return
    }

    FirebaseManager.signIn(email, password)
      .then((_) => {
        setSubmitting(false)
        this.props.router.push(TOP)
      })
      .catch((error) => {
        setSubmitting(false)
        console.error(error)
        this.setState({ isSuccess: false, signInError: error })
      })
  }

  componentDidMount() {
    if (
      !isEmpty(FirebaseManager.crtAuth.currentUser) &&
      !isEmpty(FirebaseManager.mainAuth.currentUser)
    ) {
      this.props.router.push(TOP)
    }
  }

  render() {
    const { sessionError, signInError } = this.state

    return (
      <Content className="login">
        {sessionError && (
          <SessionErrorContainer>
            セッションの有効期限切れのためログアウトされました。再度ログインしてください。
          </SessionErrorContainer>
        )}
        <LoginFormContainer className="login-form-container">
          <AppLogo
            src={logo.src}
            className="app-logo"
            alt="logo"
          />
          <LoginForm
            login={this.login}
            signInError={signInError}
          />
        </LoginFormContainer>
        <NextLink href="forgot-password">パスワードを忘れた方はこちら</NextLink>
      </Content>
    )
  }
}

export default withRouter(Login)
