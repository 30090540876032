import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'

const StyledTextField = styled(TextField)`
  && {
    width: 25vw;
    min-width: 300px;
  }
`

export default StyledTextField
