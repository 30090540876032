import type { FC } from 'react'
import React, { memo } from 'react'
import styled from 'styled-components'

type Props = {
  errorMessage: string
  textAlign?: string
}
const ValidationError = styled.div<{ textAlign?: string }>`
  width: 100%;
  margin: 5px auto;
  font-size: 1rem;
  color: red;
  text-align: ${(props) => props.textAlign || 'left'};
  white-space: pre-wrap;
`

const ValidationErrorLabel: FC<Props> = memo(({ errorMessage, textAlign }) => (
  <ValidationError
    className="validation-error"
    textAlign={textAlign}
  >
    {errorMessage}
  </ValidationError>
))

export default ValidationErrorLabel
ValidationErrorLabel.displayName = 'ValidationErrorLabel'
