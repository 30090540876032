import type { FC, MouseEventHandler } from 'react'
import { memo } from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'styles/Theme'

type Props = {
  type?: 'button' | 'submit' | 'reset'
  theme?: string
  label: string
  color?: string
  margin?: string
  height?: string
  maxHeight?: string
  minWidth?: string
  border?: string
  fontSize?: string
  fontWeight?: string
  disabled?: boolean
  icon?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const Button = styled.button<{
  theme: Props['theme']
  color?: Props['color']
  height?: Props['height']
  margin?: Props['margin']
  maxHeight?: Props['maxHeight']
  minWidth?: Props['minWidth']
  border?: Props['border']
  fontSize?: Props['fontSize']
  fontWeight?: Props['fontWeight']
  disabled?: Props['disabled']
}>`
  flex: 1;
  min-width: ${(props) =>
    props.theme === 'primary' ? '300px' : props.minWidth};
  height: ${(props) => props.height || '3rem'};
  max-height: ${(props) => props.maxHeight || '3rem'};
  padding: 0 20px;
  margin: ${(props) => props.margin || '10px'};
  font-size: ${(props) => props.fontSize || 'calc(1rem + 0.3vw)'};
  font-weight: ${(props) => props.fontWeight || '300'};
  line-height: initial;
  color: #fff;
  letter-spacing: 0.05rem;
  background: ${(props) =>
    props.color || 'linear-gradient(0deg, #49c0ad, #4fafc7)'};
  background-color: #a82ed9;
  border: none;
  border-radius: 0.4rem;
  outline: none;
  opacity: ${(props) => (props.disabled ? '0.5' : '0.9')};
  transition: all 100ms ease-in;

  &:hover {
    cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
    opacity: 0.5;
  }

  &:active {
    opacity: 0.5;
  }

  ${(props) =>
    props.theme === 'secondary' &&
    css`
      height: 2rem;
      font-size: calc(0.5rem + 0.3vw);
      background: none;
      background-color: ${(props: any) => props.color || '#1d1d1d'};
    `}

  ${(props) =>
    props.theme === 'back' &&
    css`
      background: none;
      background-color: ${colors.baseBlack};
    `}

  ${(props) =>
    props.theme === 'add' &&
    css`
      background: none;
      background-color: ${colors.baseGreen};
    `}
`

export const ActionButton: FC<Props> = memo(
  ({
    type,
    theme,
    label,
    color,
    margin,
    height,
    maxHeight,
    minWidth,
    border,
    fontSize,
    fontWeight,
    disabled,
    onClick,
  }) => (
    <Button
      type={type}
      theme={theme || 'primary'}
      color={color}
      height={height}
      margin={margin}
      maxHeight={maxHeight}
      minWidth={minWidth || '100px'}
      border={border}
      fontSize={fontSize}
      fontWeight={fontWeight}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </Button>
  ),
)

ActionButton.displayName = 'ActionButton'
