import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withFormik } from 'formik'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import * as Yup from 'yup'
import { ActionButton } from 'components/common/ActionButton'
import StyledTextField from 'components/common/StyledTextField'
import ValidationErrorLabel from 'components/ValidationErrorLabel'
import { FirebaseErrorMessage, ValidationMessage } from 'constants/Message'

const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  margin: 0 5vw 3vh;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 75%;
`

const LoginForm = (props) => {
  const {
    values,
    touched,
    errors,
    dirty,
    signInError,
    isSubmitting,
    handleChange,
    handleSubmit,
  } = props

  const isEmptyEmail = isEmpty(values.email)
  const isEmptyPassword = isEmpty(values.password)

  return (
    <Form
      className="login-form"
      onSubmit={handleSubmit}
    >
      <StyledTextField
        type="text"
        name="email"
        value={values.email}
        placeholder="メールアドレス"
        margin="normal"
        variant="outlined"
        onChange={handleChange}
        autoComplete="off"
      />
      {touched.email && errors.email && (
        <div>
          <ValidationErrorLabel errorMessage={errors.email} />
        </div>
      )}
      <br />
      <StyledTextField
        type="password"
        name="password"
        value={values.password}
        placeholder="パスワード"
        margin="normal"
        variant="outlined"
        onChange={handleChange}
      />
      {touched.password && errors.password && (
        <div>
          <ValidationErrorLabel errorMessage={errors.password} />
        </div>
      )}
      <br />
      <ButtonContainer>
        <ActionButton
          label="ログイン"
          type="submit"
          disabled={isSubmitting || !dirty || isEmptyEmail || isEmptyPassword}
        />
      </ButtonContainer>
      <br />
      {signInError && (
        <div>
          <ValidationErrorLabel
            errorMessage={
              FirebaseErrorMessage[signInError.code] ||
              FirebaseErrorMessage.unknown
            }
          />
        </div>
      )}
      <br />
      {isSubmitting ? <CircularProgress /> : null}
    </Form>
  )
}

const LoginFormWithFormik = withFormik({
  mapPropsToValues: () => {
    return {
      email: '',
      password: '',
    }
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .required(ValidationMessage.REQUIRED)
      .email(ValidationMessage.INVALID_FORMAT_EMAIL),
    password: Yup.string().required(ValidationMessage.REQUIRED),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    props.login && props.login(values, setSubmitting, props.history)
  },
})(LoginForm)

export default LoginFormWithFormik
